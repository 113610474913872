<template>
  <div class="cloud-box">

    <!-- 按钮 -->

    <!-- <div class="cloud-btn">
      <div>容量概览</div>
      <div>云盘设置</div>
    </div> -->

    <!-- 标题栏 -->

    <div class="cloud-top">
      <div class="cloud-topleft">模块</div>
      <div class="cloud-topright">
        <div class="cloud-topmake">操作权限</div>
        <div class="cloud-topset">
          <p>上传</p>
          <p>下载</p>
          <p>删除</p>
          <p>只读</p>
        </div>
      </div>
    </div>

    <!-- 权限 -->

    <div :class="i%2==0?'cloud-mouone cloud-single':'cloud-mouone cloud-double'" v-for="(item,i) in modular" :key="i">
      <div class="cloud-oneleft">{{item.title}}</div>
      <div class="cloud-oneright">
        <div v-for="(v,idx) in item.type" :key="idx">
          <p v-if="v.status">{{v.people}}</p>
          <p v-else>
            <span v-for="(item,i) in v.peoples" :key="i">{{item.label}}</span>
          </p>
          <i @click="handlepeople(i,idx)" class="el-icon-circle-plus-outline"></i>
        </div>
      </div>
    </div>

    <!-- 选择人员 -->

    <div class="cloud-choice" v-if="cloud">
      <div class="cloud-cleft">
        <div class="node-leftquree">
          <p>选择人员</p>
          <div class="node-input">
            <el-input v-model="filterText"></el-input>
            <div class="button" @click="btnA">查询</div>
          </div>
        </div>
        <div class="node-tree">
          <el-tree class="node-treebody" :data="data" show-checkbox node-key="id" :filter-node-method="filterNode"  :default-checked-keys="pitch_on_people"
            :props="defaultProps" @check-change="handlechange" :default-expand-all='true' ref="tree">
          </el-tree>
        </div>
      </div>
      <div class="cloud-cright">
        <div>
          <p class="node-righttitle">已选人员</p>
          <div class="node-per">
            <div class="node-persone" v-for="(item,i) in personnel" :key="i">
              <img :src="item.img" alt="" v-if="item.img != undefined">
              <span v-else >{{item.label.substr(0, 1)}}</span>
              <p class="node-perlabel">{{item.label}}</p>
              <i class="el-icon-circle-close node-oper" @click="handledelete(i)"></i>
            </div>
          </div>
        </div>
        <div style="text-align: center;">
          <el-button type="danger" @click="set_net_disk_auth()">  确认   </el-button>
        </div>
      </div>
      <i class="el-icon-error" @click="handleclose"></i>
    </div>



    <div class="shade" @click="giabain()" v-if="shade"></div>
  </div>
</template>

<script>
  import { query_dept_staff_tree_aim } from "../../api/pulic"

import { set_net_disk_auth,query_user_folder_auth_list } from '../../api/store'


  export default {
    data() {
      return {
        shade: false,
        cloud: false,
        index: {
          x: 0,
          y: 0
        },
        modular: [{
            module_id:'1',
            title: '目标',
            type: [{
                auth:'1',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'2',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'3',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'4',
                status: true,
                people: '全部成员',
                peoples: []
              }
            ]
          },
          {
            module_id:'2',
            title: '点单',
            type: [{
                auth:'1',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'2',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'3',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'4',
                status: true,
                people: '全部成员',
                peoples: []
              }
            ]
          },
          {
            module_id:'3',
            title: '烹饪',
            type: [{
                auth:'1',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'2',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'3',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'4',
                status: true,
                people: '全部成员',
                peoples: []
              }
            ]
          },
          {
            module_id:'4',
            title: '备餐',
            type: [{
                auth:'1',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'2',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'3',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'4',
                status: true,
                people: '全部成员',
                peoples: []
              }
            ]
          },
          {
            module_id:'5',
            title: '清洁',
            type: [{
                auth:'1',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'2',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'3',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'4',
                status: true,
                people: '全部成员',
                peoples: []
              }
            ]
          },
          {
            module_id:'6',
            title: '库房',
            type: [{
                auth:'1',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'2',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'3',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'4',
                status: true,
                people: '全部成员',
                peoples: []
              }
            ]
          },
          {
            module_id:'7',
            title: '采购',
            type: [{
                auth:'1',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'2',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'3',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'4',
                status: true,
                people: '全部成员',
                peoples: []
              }
            ]
          },
          {
            module_id:'8',
            title: '研发',
            type: [{
                auth:'1',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'2',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'3',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'4',
                status: true,
                people: '全部成员',
                peoples: []
              }
            ]
          },
          {
            module_id:'9',
            title: '质检',
            type: [{
                auth:'1',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'2',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'3',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'4',
                status: true,
                people: '全部成员',
                peoples: []
              }
            ]
          },
          {
            module_id:'10',
            title: '人力',
            type: [{
                auth:'1',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'2',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'3',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'4',
                status: true,
                people: '全部成员',
                peoples: []
              }
            ]
          },
          {
            module_id:'11',
            title: '财务',
            type: [{
                auth:'1',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'2',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'3',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'4',
                status: true,
                people: '全部成员',
                peoples: []
              }
            ]
          },
          {
            module_id:'12',
            title: '行政',
             type: [{
                auth:'1',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'2',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'3',
                status: true,
                people: '全部成员',
                peoples: []
              },
              {
                auth:'4',
                status: true,
                people: '全部成员',
                peoples: []
              }
            ]
          }
        ],
        // 搜索
        filterText: '',
        data: [], // 人员树
        personnel: [], // 选中的人员
        // 树形图的
        defaultProps: {
          children: 'children',
          label: 'label'
        },

        pitch_on_people:[],

        // =========
        qx_module_id:'', // 文件夹id
        qx_auth:'', //  操作
      }
    },
    methods: {
      giabain() {
        this.cloud = false
        this.shade = false
        if (this.modular[this.index.x].type[this.index.y].peoples.length === 0) {
          this.modular[this.index.x].type[this.index.y].status = true
        }
      },
      handleclose() {
        this.cloud = false
        this.shade = false
        if (this.modular[this.index.x].type[this.index.y].peoples.length === 0) {
          this.modular[this.index.x].type[this.index.y].status = true
        }
      },
      handlepeople(i, idx) {
        console.log('===========',i, idx);

        this.qx_module_id = this.modular[i].module_id
        this.qx_auth = this.modular[i].type[idx].auth

        this.filterText = ''
        this.cloud = true
        this.shade = true
        this.modular[i].type[idx].status = false
        this.personnel = []
        this.pitch_on_people = []
        if (this.modular[i].type[idx].peoples.length > 0) {
          console.log('ceshishishis', this.modular[i].type[idx].peoples)
          this.modular[i].type[idx].peoples.forEach(item => {
            this.personnel.unshift({
              id: item.id,
              label: item.label,
              img: item.img
            })
            this.pitch_on_people.push(item.id)
            console.log('==========this.personnel', this.personnel)
          })
        } else {
          this.personnel = []
          this.index.x = i
          this.index.y = idx
        }
      },
      btnA() {
        this.$refs.tree.filter(this.filterText)
      },
      filterNode(value, data) {
        console.log(value,data);
        if (!value) return true
        return data.label.indexOf(value) !== -1
      },
      // 删除
      handledelete(i) {
        this.$confirm('此操作将删除该人员, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {

        this.personnel.splice(i, 1)

        let  personnel = this.personnel

        let  personnel_id = []
        for (let a in personnel) {
          personnel_id.push(personnel[a].id)
        }
        console.log(personnel_id.join(','))
        let data = {
          data:{
            ent_id: this.$store.state.ent_id,
            module_id:this.qx_module_id, // 文件夹的
            auth:this.qx_auth, // 上传 下载 删除 只读
            staff_id:personnel_id.join(','), //多个都好分隔
          }
        }
        console.log(data);
        set_net_disk_auth(data).then((res)=>{
          console.log(res);
          if (res.code == '200') {
            this.$message({
                type: 'success',
                message: '删除成功!'
            });

            this.personnel = personnel
            console.log(this.personnel)
            this.query_user_folder_auth_list()
          }
        })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      },
      // 树形图
      handlechange(info, nodestatus, nodesubstatus) {
        console.log('==================',info, nodestatus, nodesubstatus);
        if (info.type == '2') {
          if (nodestatus) {
            this.personnel.unshift({
              id: info.id,
              label: info.label,
              img: info.img
            })
          } else {
            let arr = this.personnel
            for (const i in arr) {
              if (info.id === arr[i].id) {
                arr.splice(i, 1)
              }
            }
            this.personnel = arr
          }
        }
      },

      // 添加权限
      set_net_disk_auth(){
        console.log('this.qx_module_id',this.qx_module_id);
        console.log('this.qx_auth',this.qx_auth);
        console.log('this.personnel',this.personnel);
        let  personnel = this.personnel
        let  personnel_id = []
        for (let a in personnel) {
          personnel_id.push(personnel[a].id)
        }
        let data = {
          data:{
            ent_id: this.$store.state.ent_id,
            module_id:this.qx_module_id, // 文件夹的
            auth:this.qx_auth, // 上传 下载 删除 只读
            staff_id:personnel_id.join(','), //多个都好分隔
          }
        }
        console.log(data);
        set_net_disk_auth(data).then((res)=>{
          console.log(res);
          if (res.code == '200') {
            this.$message({
                type: 'success',
                message: '添加成功!'
            });
            this.cloud = false
            this.shade = false
            this.query_user_folder_auth_list()
          }
        })
      },

      // 查询权限
      query_user_folder_auth_list(){
        query_user_folder_auth_list({data:{
          ent_id: this.$store.state.ent_id,
          // user_id:this.$store.state.user_id,
        }}).then((res)=>{
          console.log('权限',res);
          if (res.code == '200') {
            let qx_list = res.body.data
            console.log(qx_list)

            let modular  = this.modular

            for (let a in modular) {
              let name =[]
              for (let b in qx_list) {
                if (modular[a].module_id == qx_list[b].module_id) {
                  for (let c in modular[a].type) {
                    if (modular[a].type[c].auth == qx_list[b].auth ) {

                      modular[a].type[c]. status = false
                      //  modular[a].type[c].people =  modular[a].type[c].people.push(qx_list[b])
                      //  modular[a].type[c].peoples =  []
                      // ----
                      modular[a].type[c].peoples.push({
                        label:qx_list[b].user_name,
                        id: qx_list[b].user_id
                      })

                      // name.push({
                      //   label:qx_list[b].user_name,
                      //   id: qx_list[b].user_id
                      // })
                      // modular[a].type[c].peoples = name






                    }
                  }
                }
              }
            }

            for (let a1 in modular) {
                for (let c1 in modular[a1].type) {
                  let obj = {};
                  modular[a1].type[c1].peoples = modular[a1].type[c1].peoples.reduce(function(item, next) {
                    obj[next.id] ? '' : obj[next.id] = true && item.push(next);
                    return item;
                  }, [] );


                  // let result = [];
                  // let obj = {};
                  // for(let i =0; i<arr.length; i++){
                  // if(!obj[arr[i].key]){
                  //   result.push(arr[i]);
                  //   obj[arr[i].key] = true;
                  // }
                  // }
                  // console.log(result)
                }
            }

            this.modular = modular

          }
        })
      },
    },
    created() {
      query_dept_staff_tree_aim({
        data: {
          ent_id: this.$store.state.ent_id,
        }
      }).then((res) => {
        this.data = res.body.data
      })
    },
    mounted(){
      this.query_user_folder_auth_list()
    }
  }
</script>

<style scoed lang="scss">
  .el-main {}

  .cloud-box {
    width: 100%;
    height: 1000px;
    font-size: 15px;

    .cloud-btn {
      display: flex;
      padding-top: 40px;
      padding-bottom: 40px;

      div {
        width: 80px;
        height: 30px;
        margin-left: 100px;
        font-size: 12px;
        line-height: 30px;
        text-align: center;
        color: #ff404e;
        border-radius: 10px;
        border: 1px solid #feb9be;
      }
    }

    .cloud-top {
      display: flex;

      .cloud-topleft {
        width: 430px;
        height: 80px;
        background-color: #efefef;
        color: #666666;
        line-height: 80px;
        text-align: center;
      }

      .cloud-topright {
        flex: 1;
        height: 80px;
        background-color: #f8f8f8;

        .cloud-topmake {
          width: 100%;
          height: 39px;
          border-bottom: 1px solid #f2f2f2;
          line-height: 39px;
          text-align: center;
        }

        .cloud-topset {
          width: 100%;
          height: 40px;
          display: flex;
          justify-content: space-around;
          align-items: center;
        }
      }
    }

    .cloud-single {
      background-color: #fefefe;
    }

    .cloud-double {
      background-color: #f8f8f8;
    }

    .cloud-mouone {
      display: flex;

      .cloud-oneleft {
        width: 430px;
        height: 70px;
        line-height: 70px;
        text-align: center;
      }

      .cloud-oneright {
        flex: 1;
        display: flex;
        justify-content: space-around;

        div {
          display: flex;
          align-items: center;

          p {
            width: 150px;
            margin-left: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .cloud-choice {
      position: fixed;
      margin: auto;
      background: white;
      top: 50%;
      left: 50%;
      display: flex;
      transform: translate(-50%, -50%);
      z-index: 23;
      border-radius: 10px;

      .el-icon-error {
        font-size: 30px;
        color: #cccccc;
        margin-top: 20px;
        margin-right: 10px;
      }

      .cloud-cleft {
        width: 462px;
        height: 558px;
        margin: 20px;
        box-shadow: 0px 0px 12px #cfc5e3;
        border-radius: 10px;
        overflow-y: auto;
      }

      .cloud-cright {
        width: 500px;
        height: 558px;
        border-radius: 10px;
        margin: 20px 10px 20px 0px;
        box-shadow: 0px 0px 12px #cfc5e3;

        .node-righttitle {
          font-size: 15px;
          color: #333333;
          margin-top: 20px;
          margin-left: 30px;
          margin-bottom: 15px;
        }

        .node-per {
          width: 100%;
          height: 450px;
          overflow-y: auto;

          .node-persone {
            width: 100%;
            height: 70px;
            display: flex;
            align-items: center;
            span{
              width: 50px;
              height: 50px;
              margin-left: 41px;
              text-align: center;
              line-height: 50px;
              color: #fff;
              border-radius: 50%;
              background: #ccc;
            }
            img {
              width: 50px;
              height: 50px;
              margin-left: 41px;
              border-radius: 50%;
            }

            p {
              font-size: 15px;
            }

            .node-perlabel {
              width: 100px;
              margin-left: 20px;
            }

            .node-oper {
              margin-left: 220px;
              font-size: 20px;
            }
          }
        }
      }
    }

    .node-leftquree {
      .node-input {
        display: flex;
        margin-top: 21px;
      }

      p {
        font-size: 15px;
        margin-left: 43px;
        margin-top: 25px;
      }

      input {
        border: 1px solid #cccccc;
        margin-left: 32px;
        width: 300px;
        height: 50px;
        border-radius: 10px;
      }

      .button {
        border: 1px solid #fc4353;
        margin-left: 20px;
        width: 97px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        border-radius: 10px;
        font-size: 18px;
        color: #fc4353;
        background-color: white;
        margin-right: 30px;
      }
    }

    .node-tree {
      width: 405px;
      height: 538px;
      margin-top: 20px;
      margin-left: 32px;
      font-size: 15px;

      .node-treebody {
        margin-left: 30px;
        margin-top: 30px;
      }
    }

    .cloud-cleft::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: rgba(240, 240, 240, 1);
    }

    .cloud-cleft::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.5);
      background-color: rgba(134, 134, 134, 0.5);
    }

    .cloud-cleft::-webkit-scrollbar-track {
      box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5);
      border-radius: 10px;
      background-color: rgba(255, 249, 249, 0.5);
    }

    .node-per::-webkit-scrollbar {
      width: 5px;
    }

    .node-per::-webkit-scrollbar-thumb {
      border-radius: 5px;
      width: 2px;
      background: #c8c8c8;
    }

    .node-per::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f6f6fa;
    }
  }
</style>